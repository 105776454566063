import { AsyncPipe } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { AufgabenTabelleComponent } from '@dworkflow/shared/components/aufgaben-tabelle/aufgaben-tabelle.component';
import { UserModel } from '@dworkflow/shared/model/security/user.model';
import { SecurityService } from '@dworkflow/shared/services/security.service';
import { TenantService } from '@dworkflow/shared/services/tenant.service';
import { guid } from '@dworkflow/shared/utility/guid';
import * as fromEngineStore from '@dworkflow/state/engine.state';
import * as pollingActions from '@dworkflow/state/polling.actions';
import * as fromSchrittStore from '@dworkflow/state/schritt.state';
import { WorkflowTabelleComponent } from '@dworkflow/workflow/workflow-tabelle/workflow-tabelle.component';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const ANZAHL_ANZUZEIGENDER_AUFGABEN = 5;

@Component({
  selector: 'dworkflow-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    RouterLink,
    MatIconModule,
    AufgabenTabelleComponent,
    WorkflowTabelleComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class HomeComponent implements OnInit, OnDestroy {
  store = inject(Store);
  securityService = inject(SecurityService);
  tenantService = inject(TenantService);

  errorMessage: string;
  meineWorkflowIds$: Observable<guid[]> = this.store.select(
    fromEngineStore.selectMeineWorkflowIdsFuerStartseite
  );
  meineWorkflowsLoaded$ = this.store.select(
    fromEngineStore.selectMeineWorkflowsFuerStartseiteLoaded
  );

  meineAufgabenIds$: Observable<guid[]> = this.store.select(
    fromSchrittStore.selectMeineAufgabenIdsFuerStartseite()
  );
  meineAufgabenLoaded$: Observable<boolean> = this.store.select(
    fromSchrittStore.selectMeineAufgabenFuerStartseiteLoaded()
  );

  currentUserDisplayName$: Observable<string> = this.securityService.getCurrentUser().pipe(
    map((user: UserModel) => {
      const names = user?.displayName ? user.displayName.split(', ') : null;
      if (names?.length === 2) {
        return `${names[1]} ${names[0]}`;
      } else {
        return user?.displayName;
      }
    })
  );

  ngOnInit(): void {
    this.store.dispatch(pollingActions.pollStartseitenWorkflows());
    this.store.dispatch(
      pollingActions.pollMeineAufgaben({ anzahl: ANZAHL_ANZUZEIGENDER_AUFGABEN })
    );

    // Um die Workflowinformationen in den obigen Aufgaben darzustellen, muessen alle WFs geladen werden
    this.store.dispatch(pollingActions.pollAlleWorkflows());
  }

  ngOnDestroy(): void {
    this.store.dispatch(pollingActions.stopPollStartseitenWorkflows());
    this.store.dispatch(pollingActions.stopPollMeineAufgaben());
    this.store.dispatch(pollingActions.stopPollAlleWorkflows());
  }
}
