import { Injectable, inject } from '@angular/core';
import { InactivityService } from '@dworkflow/shared/services/inactivity.service';
import * as commonActions from '@dworkflow/state/common.actions';
import * as engineActions from '@dworkflow/state/engine.actions';
import * as schrittActions from '@dworkflow/state/schritt.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { asyncScheduler, timer } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import * as pollingActions from './polling.actions';

const WORKFLOW_DETAIL_POLLING_INTERVAL = 5000;
const WORKFLOW_PROTOKOLL_POLLING_INTERVAL = 20000;
const MEINE_AUFGABEN_POLLING_INTERVAL = 60000;
const MEINE_WORKFLOWS_POLLING_INTERVAL = 60000;
const ALLE_WORKFLOWS_POLLING_INTERVAL = 60000;
const STARTSEITE_POLLING_INTERVAL = 60000;
const WORKFLOW_UND_AUFGABEN_ANZAHL_POLLING_INTERVAL = 60000;

@Injectable({
  providedIn: 'root',
})
export class PollingEffects {
  private actions$ = inject(Actions);
  private inactivityService = inject(InactivityService);

  // Mappt das Navigation Event auf StopPoll
  navigationStarted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      map(_ => pollingActions.stopPoll())
    );
  });

  // Workflow Detail
  pollWorkflowDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(pollingActions.pollWorkflowDetail),
      switchMap(action =>
        // Erste Emission direkt, dann alle 5 Sek
        timer(0, WORKFLOW_DETAIL_POLLING_INTERVAL, asyncScheduler).pipe(
          // Halte Interval aufrecht bis eine der folgenden Aktionen eintritt
          takeUntil(
            this.actions$.pipe(
              ofType(
                pollingActions.stopPoll,
                pollingActions.stopPollWorkflowDetail,
                // Falls die Action erneut dispatcht werden, kein doppeltes Polling,
                // sondern altes Polling beenden und ueber den Effect erneut triggern
                pollingActions.pollWorkflowDetail
              )
            )
          ),
          map(_ =>
            this.inactivityService.isUserActive()
              ? engineActions.loadWorkflow({ workflowId: action.workflowId })
              : pollingActions.noPoll()
          )
        )
      )
    );
  });

  // Workflow Protokoll
  pollWorkflowProtokoll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(pollingActions.pollWorkflowProtokoll),
      switchMap(action =>
        // Erste Emission direkt, dann alle nach spezifizierten Sek
        timer(0, WORKFLOW_PROTOKOLL_POLLING_INTERVAL, asyncScheduler).pipe(
          // Halte Interval aufrecht bis eine der folgenden Aktionen eintritt
          takeUntil(
            this.actions$.pipe(
              ofType(
                pollingActions.stopPoll,
                pollingActions.stopPollWorkflowProtokoll,
                // Falls die Action erneut dispatcht werden, kein doppeltes Polling,
                // sondern altes Polling beenden und ueber den Effect erneut triggern
                pollingActions.pollWorkflowProtokoll
              )
            )
          ),
          map(_ =>
            this.inactivityService.isUserActive()
              ? engineActions.loadProtokoll({ workflowId: action.workflowId })
              : pollingActions.noPoll()
          )
        )
      )
    );
  });

  // MeineAufgabe
  pollMeineAufgaben$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(pollingActions.pollMeineAufgaben),
      switchMap(action =>
        // Erste Emission direkt, dann alle spezifizierten Sek
        timer(0, MEINE_AUFGABEN_POLLING_INTERVAL, asyncScheduler).pipe(
          // Halte Interval aufrecht bis eine der folgenden Aktionen eintritt
          takeUntil(
            this.actions$.pipe(
              ofType(
                pollingActions.stopPoll,
                pollingActions.stopPollMeineAufgaben,
                // Falls die Action erneut dispatcht werden, kein doppeltes Polling,
                // sondern altes Polling beenden und ueber den Effect erneut triggern
                pollingActions.pollMeineAufgaben
              )
            )
          ),
          map(_ =>
            this.inactivityService.isUserActive()
              ? schrittActions.loadMeineAufgaben({ anzahl: action.anzahl ?? 0 })
              : pollingActions.noPoll()
          )
        )
      )
    );
  });

  // MeineWorkflows
  pollMeineWorkflows$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(pollingActions.pollMeineWorkflows),
      switchMap(_ =>
        // Erste Emission direkt, dann alle spezifizierten Sek
        timer(0, MEINE_WORKFLOWS_POLLING_INTERVAL, asyncScheduler).pipe(
          // Halte Interval aufrecht bis eine der folgenden Aktionen eintritt
          takeUntil(
            this.actions$.pipe(
              ofType(
                pollingActions.stopPoll,
                pollingActions.stopPollMeineWorkflows,
                // Falls die Action erneut dispatcht werden, kein doppeltes Polling,
                // sondern altes Polling beenden und ueber den Effect erneut triggern
                pollingActions.pollMeineWorkflows
              )
            )
          ),
          map(_ =>
            this.inactivityService.isUserActive()
              ? engineActions.loadMeineWorkflows()
              : pollingActions.noPoll()
          )
        )
      )
    );
  });

  // Alle Workflows
  pollAlleWorkflows$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(pollingActions.pollAlleWorkflows),
      switchMap(_ =>
        // Erste Emission direkt, dann alle spezifizierten Sek
        timer(0, ALLE_WORKFLOWS_POLLING_INTERVAL, asyncScheduler).pipe(
          // Halte Interval aufrecht bis eine der folgenden Aktionen eintritt
          takeUntil(
            this.actions$.pipe(
              ofType(
                pollingActions.stopPoll,
                pollingActions.stopPollAlleWorkflows,
                // Falls die Action erneut dispatcht werden, kein doppeltes Polling,
                // sondern altes Polling beenden und ueber den Effect erneut triggern
                pollingActions.pollAlleWorkflows
              )
            )
          ),
          map(_ =>
            this.inactivityService.isUserActive()
              ? engineActions.loadAlleWorkflows()
              : pollingActions.noPoll()
          )
        )
      )
    );
  });

  // Startseiten Workflows
  pollStartseitenWorkflows$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(pollingActions.pollStartseitenWorkflows),
      switchMap(_ =>
        // Erste Emission direkt, dann alle spezifizierten Sek
        timer(0, STARTSEITE_POLLING_INTERVAL, asyncScheduler).pipe(
          // Halte Interval aufrecht bis eine der folgenden Aktionen eintritt
          takeUntil(
            this.actions$.pipe(
              ofType(
                pollingActions.stopPoll,
                pollingActions.stopPollStartseitenWorkflows,
                // Falls die Action erneut dispatcht werden, kein doppeltes Polling,
                // sondern altes Polling beenden und ueber den Effect erneut triggern
                pollingActions.pollStartseitenWorkflows
              )
            )
          ),
          map(_ =>
            this.inactivityService.isUserActive()
              ? engineActions.loadMeineWorkflowsFuerStartseite()
              : pollingActions.noPoll()
          )
        )
      )
    );
  });

  // Anzahlen
  pollWorkflowUndAufgabenAnzahlen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      switchMap(_ =>
        // Erste Emission direkt, dann alle spezifizierten Sek
        timer(0, WORKFLOW_UND_AUFGABEN_ANZAHL_POLLING_INTERVAL, asyncScheduler).pipe(
          // Halte Interval aufrecht bis eine der folgenden Aktionen eintritt
          takeUntil(
            this.actions$.pipe(
              ofType(
                pollingActions.stopPoll,
                // Falls die Action erneut dispatcht werden, kein doppeltes Polling,
                // sondern altes Polling beenden und ueber den Effect erneut triggern
                ROUTER_NAVIGATED
              )
            )
          ),
          map(_ =>
            this.inactivityService.isUserActive()
              ? commonActions.loadAnzahlen()
              : pollingActions.noPoll()
          )
        )
      )
    );
  });
}
